<template>
  <div class="animated fadeIn">
    <b-card>
      <b-card-header>
        Invoice ID <strong>{{ detail.invoice_number }}</strong>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            sm="2"
          >
            <h6 >Order Date</h6>
            <div class="mb-3"><strong>{{ detail.created_at }}</strong></div>
          </b-col>
          <b-col
            sm="2"
          >
            <h6 >User</h6>
            <div class="mb-3"><strong>{{ detail.users.email }}</strong></div>
          </b-col>
          <b-col
            sm="2"
          >
            <h6 >Status Pembayaran</h6>
            <div class="mb-3"><strong>{{ detail.is_paid }}</strong></div>
          </b-col>
          <b-col
            sm="2"
          >
            <h6 >Admin Fee</h6>
            <div class="mb-3"><strong>{{ detail.invoice.admin_fee }}</strong></div>
          </b-col>
          <b-col
            sm="2"
          >
            <h6 >Total Pembayaran</h6>
            <div class="mb-3"><strong>{{ detail.price_total }}</strong></div>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            sm="2"
          >
            <h6 >Payment Date</h6>
            <div class="mb-3"><strong>{{ detail.invoice.payment_date }}</strong></div>
          </b-col>
          <b-col
            sm="2"
          >
            <h6 >Metode Pembayaran</h6>
            <div class="mb-3" v-for="(item, index) in detail.invoice.payment_method" :key="index"><strong>{{ item }}</strong> <br> </div>
          </b-col>
          <b-col
            sm="2"
          >
            <h6 >Total Harga Emas</h6>
            <div class="mb-3"><strong>{{ detail.price }}</strong></div>
          </b-col>
          <b-col
            sm="2"
          >
            <h6 >Pajak</h6>
            <div class="mb-3"><strong>{{ detail.invoice.tax }}</strong></div>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12">
            <div class="vutable-body-wrapper">
      <table class="vuetable ui blue selectable unstackable celled table fixed">
        <thead>
          <tr>
            <th class="text-center">Gramasi</th>
            <th class="text-center">Harga Beli Emas</th>
            <th class="text-center">Harga Emas</th>
            <th class="text-center">Penerima</th>
            <th class="text-center">Status</th>
          </tr>
        </thead>
        <tbody class="vuetable-body">
          <tr>
            <td class="text-left">{{ detail.receiver.gold_amount }}</td>
            <td class="text-left">{{ detail.receiver.buying_rate }}</td>
            <td class="text-left">{{ detail.price }}</td>
            <td class="text-left">{{ detail.receiver.phone }}</td>
            <td class="text-left">{{ detail.receiver.status }}</td>
          </tr>
        </tbody>
      </table>
    </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
export default {
  name: 'detail-hadiah-emas-transaction',
  data() {
    return {
      detail: {
        invoice_number: '',
        create_at: '',
        users: {
          name: '',
          email: ''
        },
        is_paid: '',
        price: '',
        price_total: '',
        invoice: {
          tax: '',
          admin_fee: '',
          payment_method: [],
          status: '',
          payment_date: ''
        },
        receiver: {
          phone: '',
          gold_amount: '',
          status: '',
          buying_rate: ''
        }
      }
    }
  },
  created() {
    this.$http.get(`gift-card-transaction/${this.$route.params.id}`).then((res) => {
      const result = res.data.data
      this.detail.invoice_number = result.invoice_number
      this.detail.created_at = result.created_at
      this.detail.users.name = result.users.name
      this.detail.users.email = result.users.email
      this.detail.is_paid = result.is_paid
      this.detail.price = result.price
      this.detail.price_total = result.price_total
      this.detail.invoice.tax = result.invoice.tax
      this.detail.invoice.admin_fee = result.invoice.admin_fee
      this.detail.invoice.payment_method = result.invoice.payment_method
      this.detail.invoice.status = result.invoice.status
      this.detail.invoice.payment_date = result.invoice.payment_date
      this.detail.receiver.phone = result.receiver.phone
      this.detail.receiver.buying_rate = result.receiver.buying_rate
      this.detail.receiver.status = result.receiver.status
      this.detail.receiver.gold_amount = result.receiver.gold_amount
    })
  }
}
</script>
